
import FileUploadInput from '../../file-upload-input.vue';
import { OPTION_COMPANY, OPTION_FUND, OPTION_OTHER } from '../../../mixins/company-classification';

export default {
    name: 'SheetBook',
    components: { FileUploadInput },
    props: {
        bulkCompanies: {
            type: Array,
            default: () => []
        },
        event: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            downloadRequestFinished: false,
            downloadId: 0,
            permissionError: false,
            promise: null,
            requestData: {
                companyIds: this.bulkCompanies.map((el) => el.id),
                format: 'pdf',
                frontPage: true,
                eventId: this.event ? this.event.id : null,
                sections: {
                    managementAndBoard: true,
                    investmentSummary: true,
                    driversAndRisks: true,
                    milestones: true,
                    atmInfo: true,
                    pressReleases: true,
                    investors: true,
                    holders: true,
                    holdings: true,
                    projections: true,
                    peerGroup: true,
                    expandedPeerGroup: false,
                    sessionsMeetingRequests: true,
                    institutionalEngagement: true
                }
            },

            sectionLabels: [
                { key: 'managementAndBoard', label: 'Management and Board' },
                { key: 'investmentSummary', label: 'Investment Summary' },
                { key: 'driversAndRisks', label: 'Drivers and Risks' },
                { key: 'milestones', label: 'Milestones' },
                { key: 'atmInfo', label: 'ATM Info' },
                { key: 'pressReleases', label: 'Press Releases' },
                { key: 'investors', label: 'Investors' },
                { key: 'holders', label: 'Holders' },
                { key: 'holdings', label: 'Holdings' },
                { key: 'projections', label: 'Projections' },
                { key: 'peerGroup', label: 'Peer Group' },
                { key: 'expandedPeerGroup', label: 'Extended Peer Group' },
                { key: 'sessionsMeetingRequests', label: 'Sessions Meeting Requests' },
                { key: 'institutionalEngagement', label: 'Institutional Engagement' }
            ]
        };
    },
    computed: {
        formatOptions() {
            const options = [];
            options.push({ value: 'pdf', label: 'PDF' });
            if (this.isUserWithRoleResearchAnalyst) {
                options.push({ value: 'docx', label: 'Word' });
            }

            return options;
        }
    },
    mounted() {
        const classification = this.checkClassifications(this.bulkCompanies);
        if (classification === OPTION_FUND) {
            this.requestData.sections.holders = false;
            this.requestData.sections.holdings = true;
        } else if (classification === OPTION_COMPANY) {
            this.requestData.sections.holders = true;
            this.requestData.sections.holdings = false;
        } else if (classification === OPTION_OTHER) {
            this.requestData.sections.holders = true;
            this.requestData.sections.holdings = true;
        }
    },
    watch: {
        'requestData.sections.expandedPeerGroup'(newValue) {
            const index = this.sectionLabels.findIndex((item) => item.key === 'expandedPeerGroup');

            if (newValue) {
                console.log('Expanded Peer Group selected.');
                if (index !== -1) {
                    // Insert `addCompanyDescription` immediately after `expandedPeerGroup`
                    this.sectionLabels.splice(index + 1, 0, {
                        key: 'addCompanyDescription',
                        label: 'Add Company Descriptions to Peer Group Companies'
                    });
                    this.$set(this.requestData.sections, 'addCompanyDescription', false);
                }
            } else {
                console.log('Expanded Peer Group unselected.');
                // Remove `addCompanyDescription` if it exists
                const descriptionIndex = this.sectionLabels.findIndex((item) => item.key === 'addCompanyDescription');
                if (descriptionIndex !== -1) {
                    this.sectionLabels.splice(descriptionIndex, 1);
                    this.$delete(this.requestData.sections, 'addCompanyDescription');
                }
            }
        }
    },
    methods: {
        checkClassifications() {
            const classifications = new Set(this.bulkCompanies.map(company => company.classification));
            if (classifications.size === 1) {
                const classification = classifications.values().next().value;
                if (classification === OPTION_FUND || classification === OPTION_COMPANY) {
                    return classification;
                }
            }
            return OPTION_OTHER;
        },
        run() {
            this.promise = new Promise((resolve, reject) => {
                this.$emit("loading", true);
                this.$axios.post("/api/company_sheets/book", this.requestData)
                    .then((response) => {
                        this.loading = false;
                        this.downloadId = response.data.downloadId;
                        this.$emit("loading", false);
                    })
                    .catch((error) => {
                        this.permissionError = error.response.status === 401 || error.response.status === 403;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.downloadRequestFinished = true;
                        this.$emit("loading", false);
                        this.$emit("finished", true);
                    });
            });
            return this.promise;
        },
        reset() {
            this.downloadRequestFinished = false;
            this.loading = false;
            this.$emit('finished', false);
            this.$emit('loading', false);
            this.requestData.sections = {
                managementAndBoard: true,
                investmentSummary: true,
                driversAndRisks: true,
                milestones: true,
                atmInfo: true,
                pressReleases: true,
                investors: true,
                holders: true,
                holdings: true,
                projections: true,
                peerGroup: true,
                expandedPeerGroup: false,
                sessionsMeetingRequests: true,
                institutionalEngagement: true
            };
        }
    }
};
