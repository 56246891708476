
import moment from "moment/moment";
import {mapActions} from "vuex";
import DownloadSelectColumnsModal from "../download-select-columns-modal";
import EditCompanyGroupModal from "../company/edit-company-group-modal";
import ConfirmModal from "../confirm-modal";
import HistoryModal from "../history-modal";
import TableActions from "../table-actions";
import TableFilters from "../table-filters.vue";
import FavStar from "../fav-star.vue";
import modals from "../../mixins/modals";
import CompanyGroupBulkOperationModal from "./company-group-bulk-operation-modal";
import CompanyBulkOperationModal from "./company-bulk-operation-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "CompanyGroupsTable",
    components: {
        FavStar,
        TableFilters,
        CompanyBulkOperationModal,
        DownloadSelectColumnsModal,
        ConfirmModal,
        HistoryModal,
        TableActions,
        EditCompanyGroupModal,
        CompanyGroupBulkOperationModal
    },
    mixins: [optionButtonTooltip, modals],
    props: {
        companyId: {
            type: Number,
            default: null,
        },
        ownOnly: {
            type: Boolean,
            default: false,
        },
        onCompanyPage: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            empty: [],
            companyGroupUpdated: moment().unix(),
            companyBulkUpdated: moment().unix() + 1,
            loading: false,
            bulkTriggered: false,
            companyGroupToDelete: null,
            editModal: null,
            companyGroupForHistory: null,
            selectedAuthor: null,
            privateFilter: null,
            privateFilterOptions: [
                {
                    title: "Private",
                    value: 1,
                },
                {
                    title: "Public",
                    value: 0,
                }
            ],
            downloadQuery: null,
            config: {
                ajaxUrl: this.companyId ? `/api/company_groups/list?fromCgc=${true}` : `/api/company_groups/list`,
                search: true,
                pagination: 25,
                columns: [
                    {
                        headline: "Name",
                        sort: true,
                    },
                    {
                        headline: "Companies",
                        sort: true,
                    },
                    {
                        headline: "Temporary",
                        ignoreEmpty: true,
                    },
                    {
                        headline: "Author",
                        sort: true,
                        hidden: this.ownOnly,
                    },
                    {
                        headline: "Last Change",
                        sort: true,
                        breakpoint: this.companyId ? "all" : "lg",
                    },
                    {
                        headline: "Created At",
                        sort: true,
                        breakpoint: this.companyId ? "all" : "lg"
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        ignoreEmpty: true,
                    },
                    {
                        headline: "Description",
                        breakpoint: "all",
                    },
                    {
                        headline: "Last Action",
                        breakpoint: "all",
                    },
                    {
                        headline: "Changed By",
                        breakpoint: "all",
                    },
                ],
                hideEmptyColumns: true,
                selectAll: true,
                select: true,
                prettySelect: true,
                selectPosition: "pre",
            },
            companyGroupToEdit: null,
            deleteFromCompanyGroupId: null,
            pageChanged: false,
            ajaxRows: [],
            contactList: {},
            preSelectedRows: [],
            preSelectedRowIds: [],
            selectedRows: [],
            bulkFilters: {},
            companyBulkFilters: null,
            bulkModal: null,
            selectedCompanyIds: null,
        }
    },
    computed: {
        filters() {
            return {
                author: this.selectedAuthor ? this.selectedAuthor.id : null,
                companyId: this.companyId ? this.companyId : null,
                privateFilter: this.privateFilter,
            };
        },

    },
    watch: {
        filters() {
            this.pageChanged = true;
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].companyListId)) {
                        this.preSelectedRowIds.push(rows[i].companyListId);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].companyListId;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.companyListId).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }

            this.bulkFilters = {
                ids: this.preSelectedRowIds
            }
        },
        selectedCompanyIds() {
            this.companyBulkFilters = this.selectedCompanyIds
        },
        ownOnly: {
            handler(val) {
                if (val) {
                    this.selectedAuthor = {
                        id: this.userInfo.id,
                        name: this.userInfo.name
                    };
                }
            },
            immediate: true
        },
    },
    mounted() {
        // If there is an ID in the query string: open modal!
        if (this.$route.query.companyGroupId && !this.ownOnly) {
            const companyGroupId = parseInt(this.$route.query.companyGroupId.toString());
            if (companyGroupId) {
                this.editCompanyGroup(companyGroupId);
            }
        }
    },
    created() {
        this.setCurrentEmail(false);
    },
    methods: {
        deleteCompanyGroup(id) {
            this.companyGroupToDelete = id;
            this.openModal(this.$refs.deleteCompanyGroupModal);
        },
        doDeleteCompanyGroup(id) {
            this.loading = true;

            this.$axios.delete("/api/company_groups/" + id).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Deleted",
                    message: "Group deleted",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.companyGroupUpdated = moment().unix()
            });
        },
        cancelDeleteCompanyGroup() {
            this.companyGroupToDelete = null
        },
        openHistory(id) {
            this.companyGroupForHistory = id;
            this.$nextTick(() => {
                this.openModal(this.$refs.historyModal);
            });
        },
        onRowsUpdated(data) {
            this.pageChanged = false;
            this.ajaxRows = data.rows;
            this.preSelectedRows = structuredClone(this.preSelectedRowIds).map(id => {
                return {
                    key: "companyListId",
                    value: id
                }
            });
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },
        onPageChange() {
            this.pageChanged = true;
        },
        openBulkOperationModal() {
            this.bulkModal = this.openModal(this.$refs.companyGroupBulkOperationModal)
        },
        clearPreSelection() {
            this.preSelectedRows = [];
            this.preSelectedRowIds = [];
            this.selectedRows = [];
        },
        downloadCompanies(groupId) {
            this.downloadQuery = {
                filters: {
                    companyGroupIds: [groupId]
                },
            };

            this.openModal(this.$refs.downloadCompaniesModal);
        },
        favUpdated() {
            this.companyGroupUpdated = moment().unix()
        },

        createSharedWithUsersTitle(sharedWithUserIds, creatingUserId, creatingUserName) {
            let title = '';

            if (this.userInfo.id === creatingUserId) {
                title += 'Shared by you';
            } else {
                title += `Shared by ${creatingUserName}`;
            }

            if (sharedWithUserIds.includes(this.userInfo.id)) {
                title += ` with you`;
                if (sharedWithUserIds.length - 1 > 0) {
                    title += ` and ${this.pluralize(sharedWithUserIds.length - 1, 'other user')}`;
                }
            } else {
                title += ` with ${this.pluralize(sharedWithUserIds.length, 'user')}`;
            }

            return title;
        },
        onCompanyGroupUpdated() {
            this.companyGroupUpdated = moment().unix()
        },
        editCompanyGroup(id) {
            this.companyGroupToEdit = id;
            this.editModal = this.openModal(this.$refs.editCompanyGroupModal);
        },
        closeCompanyGroupModal() {
            this.closeModal(this.editModal);
        },
        deleteFromCompanyGroup(id) {
            this.deleteFromCompanyGroupId = id;
            this.openModal(this.$refs.deleteFromCompanyGroupModal);
        },
        doDeleteFromCompanyGroup() {
            this.loading = true;
            this.$axios.delete("/api/company_group_companies/" + this.deleteFromCompanyGroupId).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Removed",
                    message: "Company removed from group",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
                this.companyGroupUpdated = moment().unix()
            });
        },
        cancelDeleteFromCompanyGroup() {
            this.deleteFromCompanyGroupId = null
        },
        onBulkSubmitted() {
            this.companyGroupUpdated = moment().unix();
            this.clearPreSelection();
            this.closeModal(this.bulkModal);
        },
        companyBulk(selectedCompanyIds) {
            this.bulkTriggered = true;
            this.selectedCompanyIds = selectedCompanyIds;
            this.closeModal(this.bulkModal);
            this.companyBulkUpdated = moment().unix();
            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.companyBulkOperationModal)
            })
        },
        ...mapActions({
            setCurrentEmail: "app/setCurrentEmail"
        }),
        addCompanyGroup() {
            if (this.onCompanyPage) {
                this.$emit("add-company-group");
            } else {
                this.companyGroupToEdit = null
                this.editModal = this.openModal(this.$refs.editCompanyGroupModal);
            }
        },
        createCleansingGroups() {
            this.$axios.get("/api/company_groups/create_cleansing").then(
                response => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: response.data.groups !== undefined ? (`Created ${response.data.groups} new groups with ${response.data.companies} companies`) : "Server ran out of memory",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.onCompanyGroupUpdated();
                }
            )
        },
        deleteCompanyGroupFromModal(id) {
            this.closeCompanyGroupModal();
            this.doDeleteCompanyGroup(id);
        },
    }
}
